<template>
  <v-container class="noPaddingTopBottom">
    <!-- Row for Property Details -->
    <v-row>
      <!-- Col for Property Details -->
      <v-col cols="12" lg="9" class="noPaddingTopBottom">
        <v-row>
          <v-col
              cols="12"
              :class="
              $vuetify.breakpoint.mobile ? 'noPadding' : 'noPaddingTopBottom'
            "
          >
            <CoolLightBox :items="imgList" :index="index" @close="index = null">
            </CoolLightBox>
            <v-carousel
                :height="$vuetify.breakpoint.mobile ? '450' : '750'"
                hide-delimiter-background
                cycle
                show-arrows-on-hover
                style="border: 0.5px solid gray; box-shadow: 0 0 15px #ccc"
            >
              <v-carousel-item
                  v-for="(img, i) in imgList"
                  :key="i"
                  lazy-src="../assets/images/photoloading.gif"
              >
                <img :src="img" :alt="img" width="100%" height="100%" @click="index = i"/>
              </v-carousel-item>
            </v-carousel>
            <div
                style="
                text-align: right !important;
                float: right;
                padding-right: 75px;
                margin-top: -75px;
              "
                id="create"
            >
              <v-speed-dial
                  v-model="fab"
                  :direction="$vuetify.breakpoint.mobile ? 'left' : 'top'"
                  :transition="
                  $vuetify.breakpoint.mobile
                    ? 'slide-x-reverse-transition'
                    : 'slide-y-reverse-transition'
                "
              >
                <template v-slot:activator>
                  <v-btn
                      v-model="fab"
                      color="blue darken-2"
                      dark
                      style="margin: 2px !important"
                      fab
                  >
                    <v-icon> mdi-share-variant-outline</v-icon>
                  </v-btn>
                </template>
                <ShareNetwork
                    network="telegram"
                    :url="shareUrl"
                    key="telegram"
                    :title="(propertyDoc && propertyDoc.title) || ''"
                    :description="(propertyDoc && propertyDoc.desc) || ''"
                    :quote="(propertyDoc && propertyDoc.title) || ''"
                    :media="(propertyDoc && propertyDoc.url1) || ''"
                    hashtags="bayonapp"
                >
                  <v-btn
                      fab
                      dark
                      small
                      style="margin: 2px !important"
                      color="blue"
                  >
                    <v-icon
                        style="transform: rotate(-45deg); margin: 0 0 5px 5px"
                    >mdi-send
                    </v-icon
                    >
                  </v-btn>
                </ShareNetwork>
                <ShareNetwork
                    network="linkedin"
                    :url="shareUrl"
                    :title="(propertyDoc && propertyDoc.title) || ''"
                    :description="(propertyDoc && propertyDoc.desc) || ''"
                    :quote="(propertyDoc && propertyDoc.title) || ''"
                    :media="(propertyDoc && propertyDoc.url1) || ''"
                    hashtags="bayonapp"
                >
                  <v-btn
                      fab
                      dark
                      small
                      style="margin: 2px !important"
                      color="green"
                  >
                    <v-icon>mdi-linkedin</v-icon>
                  </v-btn>
                </ShareNetwork>
                <ShareNetwork
                    network="twitter"
                    :url="shareUrl"
                    :title="(propertyDoc && propertyDoc.title) || ''"
                    :description="(propertyDoc && propertyDoc.desc) || ''"
                    :quote="(propertyDoc && propertyDoc.title) || ''"
                    :media="(propertyDoc && propertyDoc.url1) || ''"
                    hashtags="bayonapp"
                >
                  <v-btn
                      fab
                      dark
                      small
                      style="margin: 2px !important"
                      color="blue"
                  >
                    <v-icon>mdi-twitter</v-icon>
                  </v-btn>
                </ShareNetwork>
                <ShareNetwork
                    network="facebook"
                    :url="shareUrl"
                    :title="(propertyDoc && propertyDoc.title) || ''"
                    :description="(propertyDoc && propertyDoc.desc) || ''"
                    :quote="(propertyDoc && propertyDoc.title) || ''"
                    :media="(propertyDoc && propertyDoc.url1) || ''"
                    hashtags="bayonapp"
                >
                  <v-btn
                      fab
                      dark
                      small
                      style="margin: 2px !important"
                      color="indigo"
                  >
                    <v-icon>mdi-facebook</v-icon>
                  </v-btn>
                </ShareNetwork>
              </v-speed-dial>
            </div>
            <span
                class="property-price"
                :style="$vuetify.breakpoint.mobile ? 'margin-left: 12px' : ''"
            >
              {{ $t(propertyDoc.type) }} &nbsp; For
              {{ $t(propertyDoc.groupType) }} &gt;&gt;
              {{ propertyDoc.currencySymbol || "$" }}
              {{ propertyDoc && propertyDoc.price | separateNumber }}
            </span>
          </v-col>
        </v-row>

        <v-row>
          <!-- Property Measurement -->
          <v-col cols="9" lg="8" class="noPaddingTopBottom">
            <h1 class="font-weight-bold basil--text">
              {{ (propertyDoc && propertyDoc.title) || "" }}
            </h1>
            <v-row class="mb-5">
              <v-col class="noPaddingTopBottom" cols="6"
              >{{ $t("size") }}
              </v-col
              >
              <v-col class="noPaddingTopBottom" cols="6">
                :
                <span class="mainColor"
                >{{ (propertyDoc && propertyDoc.size) || "" }}
                  {{ propertyDoc.dimension || "m" }}<sup>2</sup></span
                >
              </v-col>
              <v-col class="noPaddingTopBottom" cols="6"
              >{{ $t("width") }}
              </v-col
              >
              <v-col class="noPaddingTopBottom" cols="6"
              >:
                <span class="mainColor"
                >{{ (propertyDoc && propertyDoc.width) || "" }}
                  {{ propertyDoc.dimension || "m" }}</span
                ></v-col
              >

              <v-col class="noPaddingTopBottom" cols="6"
              >{{ $t("depth") }}
              </v-col
              >
              <v-col class="noPaddingTopBottom" cols="6"
              >:
                <span class="mainColor"
                >{{ (propertyDoc && propertyDoc.depth) || "" }}
                  {{ propertyDoc.dimension || "m" }}</span
                ></v-col
              >

              <v-col
                  class="noPaddingTopBottom"
                  cols="6"
                  v-if="propertyDoc.titleType"
              >{{
                  $t("titleType")
                }}
              </v-col
              >
              <v-col
                  class="noPaddingTopBottom"
                  cols="6"
                  v-if="propertyDoc.titleType"
              >:
                <span class="mainColor">{{
                    $t(propertyDoc.titleType || "")
                  }}</span></v-col
              >

              <v-col
                  class="noPaddingTopBottom"
                  cols="6"
                  v-if="!!propertyDoc.numBed"
                  style="border-bottom: 0px !important"
              >{{ $t("bed") }}
              </v-col>
              <v-col
                  class="noPaddingTopBottom"
                  cols="6"
                  v-if="!!propertyDoc.numBed"
                  style="border-bottom: 0px !important"
              >:
                <span class="mainColor">{{
                    propertyDoc.numBed || 0
                  }}</span></v-col
              >
              <v-col
                  class="noPaddingTopBottom"
                  cols="6"
                  v-if="!!propertyDoc.numBathroom"
                  style="border-bottom: 0px !important"
              >{{ $t("bathroom") }}
              </v-col
              >

              <v-col
                  class="noPaddingTopBottom"
                  cols="6"
                  v-if="!!propertyDoc.numBathroom"
                  style="border-bottom: 0px !important"
              >:
                <span class="mainColor">{{
                    propertyDoc.numBathroom || 0
                  }}</span></v-col
              >

              <v-col class="noPaddingTopBottom" cols="6"
              >{{ $t("propertyType") }}
              </v-col
              >
              <v-col class="noPaddingTopBottom" cols="6"
              >:
                <span class="mainColor">{{
                    $t(propertyDoc.type || "")
                  }}</span></v-col
              >
            </v-row>

            <div>
              <h2 class="font-weight-bold basil--text">
                {{ $t("description") }}
              </h2>
              <pre
                  style="
                  font-family: 'Noto Sans Khmer', roboto, sans-serif;
                  white-space: pre-wrap; /* Since CSS 2.1 */
                  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
                  white-space: -pre-wrap; /* Opera 4-6 */
                  white-space: -o-pre-wrap; /* Opera 7 */
                  word-wrap: break-word; /* Internet Explorer 5.5+ */
                "
              ><p>{{ propertyDoc.desc }}</p></pre>
            </div>
            <div v-if="propertyDoc.type !== 'condo' && !$vuetify.breakpoint.mobile">
              <div v-if="propertyDoc.hasOwnProperty('nearBy')">
                <h2 class="font-weight-bold basil--text">{{ $t('nearbyPlaces') }}</h2>
                <p>
                  <ul>
                    <li v-for="(place, index) in propertyDoc.nearBy" v-bind:key="index">{{ place.distance }} km from
                      {{ place.place }}
                    </li>
                  </ul>
                </p>
              </div>
              <h2 class="font-weight-bold basil--text">
                {{ $t("locationOnMap") }}
              </h2>
              <p>
                <v-icon style="color: green">mdi-map-marker</v-icon>
                {{ (propertyDoc && propertyDoc.address) || "" }}
              </p>
            </div>
          </v-col>
          <!-- Property Poster -->
          <v-col cols="12" lg="4" class="noPaddingTopBottom">
            <v-card
                class="mx-auto"
                max-width="100%"
                style="padding: 30px 10px 30px 10px; background-color: #efefef"
                outlined
            >
              <div class="text-center">
                <v-avatar color="gray" size="130">
                  <img
                      :alt="propertyDoc.userDoc.profile.fullName"
                      v-if="propertyDoc.userDoc.url"
                      :src="propertyDoc.userDoc.url || '../assets/user.png'"
                      class="custom-border-style"
                  />
                  <img
                      :alt="propertyDoc.userDoc.profile.fullName"
                      v-else
                      src="../assets/user.png"
                      class="custom-border-style"
                  />
                </v-avatar>
                <h3>
                  <a
                      :href="
                      !!propertyDoc.userDoc.agentId
                        ? '/agent/' + propertyDoc.userDoc.agentId
                        : '/agents'
                    "
                      class="agentLink"
                  >{{ propertyDoc.userDoc.profile.fullName }}</a
                  >
                </h3>
                <p style="color: teal; font-weight: bold; margin-bottom: 0">
                  {{ propertyDoc.userDoc.userType }}
                </p>
                <p style="margin-bottom: 0">
                  <v-icon>mdi-phone</v-icon>
                  {{ propertyDoc.userDoc.phoneNumber }}
                </p>
                <p>
                  <v-icon>mdi-map-marker</v-icon>
                  {{ propertyDoc.locationDoc.name }}
                </p>
                <span>
                {{ $t("propertyId") }} :
                <span style="color: #9c27b0">{{ propertyDoc.code || "" }}</span
                ></span><br/>
                <span
                >{{ $t("publish") }} :
                  <span style="color: #9c27b0">
                    {{ propertyDoc.createdAt | momentFormatM }}</span
                  ></span
                ><br/>
                <v-icon size="16" style="color: blue">mdi-eye</v-icon>
                {{ $t("view") }} :
                <span style="color: #9c27b0">
                  {{ propertyDoc.view + 1 || 0 }}
                </span><br/><br/>
                <!-- QR Code -->
                <vue-qrcode style="max-width: 140px !important" :value="link"/>

              </div>
            </v-card>
          </v-col>
        </v-row>
        <!-- Amenity for condo only -->
        <v-row>
          <v-col cols="12" v-if="propertyDoc.type === 'condo' && amenityListOptResult.length > 0">
            <h2 class="font-weight-bold basil--text">{{ $t("amenity") }}</h2>
            <v-row>
              <v-col
                  v-for="(d, i) in amenityListOptResult"
                  :key="i"
                  cols="6"
                  sm="3"
                  md="3"
              >
                <v-checkbox
                    style="
                    margin-top: 0px !important;
                    padding-top: 0px !important;
                  "
                    v-model="propertyDoc.additional"
                    :label="d.name"
                    readonly
                    color="green"
                    :value="d.value"
                    hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- Location on map -->
        <v-row>
          <v-col cols="12">
            <div v-if="propertyDoc.type === 'condo' || $vuetify.breakpoint.mobile">
              <div v-if="propertyDoc.hasOwnProperty('nearBy')">
                <h2 class="font-weight-bold basil--text">{{ $t('nearbyPlaces') }}</h2>
                <p>
                  <ul>
                    <li v-for="(place, index) in propertyDoc.nearBy" v-bind:key="index">{{ place.distance }} km from
                      {{ place.place }}
                    </li>
                  </ul>
                </p>
              </div>
              <h2 class="font-weight-bold basil--text">
                {{ $t("locationOnMap") }}
              </h2>
              <p>
                <v-icon style="color: green">mdi-map-marker</v-icon>
                {{ (propertyDoc && propertyDoc.address) || "" }}
              </p>
            </div>
            <gmaps-map
                :options="mapOptions"
                :style="
                $vuetify.breakpoint.mobile ? 'height:400px' : 'height: 450px'
              "
            >

              <gmaps-circle v-if="propertyDoc.hasOwnProperty('isMarker') && !propertyDoc.isMarker"
                            :center="circle.center"
                            :radius="circle.radius"
                            fillColor="purple"
                            :strokeWeight="0"
                            @click="renderInfoWindow=true"/>
              <gmaps-marker v-else :options="optionsA" @click="renderInfoWindow=true"/>
              <gmaps-info-window
                  v-if="renderInfoWindow"
                  :options="infoOptions"
                  @closed="renderInfoWindow=false"
              >
                <p>
                  <v-img
                      lazy-src="../assets/images/no-image-icon.png"
                      :src="
                      (propertyDoc &&
                        propertyDoc.urlList &&
                        propertyDoc.urlList[0]) ||
                      propertyDoc.url1 ||
                      '../assets/images/No_image_available.png'
                    "
                      height="80px"
                      class="white--text align-end"
                      width="120px"
                      alt="gmaps"
                  >
                    <template v-slot:placeholder>
                      <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                      >
                        <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                    <span style="background-color: #9c27b0"
                    >&nbsp;&nbsp;{{ propertyDoc.currencySymbol || "$" }}
                      {{
                        propertyDoc && propertyDoc.price | separateNumber
                      }}&nbsp;&nbsp;</span
                    >
                  </v-img>
                </p>
                <h3>{{ (propertyDoc && propertyDoc.title) || "" }}</h3>
                <h4>{{ (propertyDoc && propertyDoc.code) || "" }}</h4>
              </gmaps-info-window>
            </gmaps-map>
            <h5 style="font-weight: normal; font-style: italic">
              *** {{ $t("usingMap") }}
            </h5>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" v-if="!!videoEmbedCode">
            <h2 class="font-weight-bold basil--text">{{ $t("ourVideo") }}</h2>
            <iframe
                v-show="!!videoEmbedCode"
                width="100%"
                :height="$vuetify.breakpoint.mobile ? '400' : '450'"
                :src="videoEmbedCode"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
            ></iframe>
          </v-col>
        </v-row>
      </v-col>
      <!-- Col for Ads -->
      <v-col
          cols="12"
          lg="3"
          v-if="!$vuetify.breakpoint.mobile"
      >
        <h3 class="font-weight-bold basil--text align-center">Recommended Agents</h3>
        <v-col cols="12"
               v-for="d in randomAgentsList"
               :key="d._id"
        >
          <v-hover
              v-slot="{ hover }"
              close-delay="100"
          >
            <v-card :to="'/agent/'+ d._id" :elevation="hover ? 1 : 0" :class="{ 'on-hover': hover }" outlined
                    class="mx-auto animate__animated animate__fadeInRight animate__duration-5s">
              <v-list-item-content style="padding: 0 !important">
                <v-col cols="4">
                  <v-avatar
                      color="brown"
                      size="70"
                  >
                    <img
                        v-if="d.photo===''  || d.photo===undefined"
                        :alt="d.name"
                        style="background-size: contain"
                        src="@/assets/user.png"
                    />
                    <img
                        :alt="d.name"
                        v-else
                        style="background-size: contain"
                        :src="d.photo"
                    />
                  </v-avatar>
                </v-col>
                <v-col cols="8">
                  <h3>{{ d.name }}</h3>
                  <p class="text-caption">
                    Tel: {{ d.phoneNumber }}
                  </p>
                  <p class="text-caption">
                    Total Property: {{ d.totalProperty }}
                  </p>
                </v-col>
              </v-list-item-content>
            </v-card>
          </v-hover>
        </v-col>
        <!-- <br />
        <h3>Place Your Ads Here</h3>
        <v-img src="../assets/adsBanner.jpeg" alt="adsBanner"> </v-img> -->
        <!-- vertical_ad -->
        <!-- <ins class="adsbygoogle"
            style="display:block"
            data-ad-client="ca-pub-3013678611793940"
            data-ad-slot="4427093308"
            data-ad-format="auto"
            data-full-width-responsive="true"></ins> -->
      </v-col>
    </v-row>
    <!-- Row for Other Property -->
    <v-row>
      <v-col cols="12">
        <h2 class="font-weight-bold basil--text">
          {{ $t("otherProperty") }}
        </h2>
        <v-row>
          <v-col cols="6" sm="4" md="3" v-for="d in propertyList" :key="d._id">
            <property-card :d="d"></property-card>
          </v-col>
          <v-col cols="12" style="text-align: center">
            <v-pagination
                v-model="page"
                :length="Math.ceil(totalPage / 12)"
                :total-visible="9"
                circle
            ></v-pagination>
          </v-col
          >
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {Constants} from "../libs/constant";
import {gmapsMap, gmapsMarker, gmapsInfoWindow, gmapsCircle} from "x5-gmaps";
import {countyMobileList} from "@/libs/countryMobileWithFlag";

import MainMixin from "../mixins/mainMixin";
import {provider} from "@/service/provider";
import axios from "axios";
import VueQrcode from "vue-qrcode";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import propertyCard from "../components/propertyCard.vue";
import _ from "lodash";

export default {
  mounted() {
    this.$jQuery("body").off();
    this.$GMaps().then((GMaps) => {
      this.optionsA.animation = GMaps.Animation.BOUNCE;
    });
    // (window.adsbygoogle = window.adsbygoogle || []).push({});
  },
  mixins: [MainMixin],
  name: "Property",
  components: {
    gmapsMap,
    gmapsMarker,
    gmapsInfoWindow,
    VueQrcode,
    CoolLightBox,
    propertyCard,
    gmapsCircle
  },
  data() {
    return {
      link: "",
      bottomValue: 1,
      active: true,
      fab: false,
      tabs: null,
      amenityListOpt: Constants.amenityList,
      amenityListOptResult: [],
      shareUrl: "",
      dense: this.$store.state.isDense,
      isLoading: false,
      fullPage: false,
      loading: true,
      valid: true,
      pageList: [10, 20, 50, 100, 200],
      imgList: [],

      dataLists: [],
      statusList: Constants.productStatusList,
      status: "All",
      loadingItem: false,
      propertyId: "",
      tab: null,
      items: [],
      imgUrlBig:
          'https://cdn.shopify.com/s/files/1/2695/0984/propertys/Navy_Cutout_Final.png?v=1546888246"',
      mapOptions: {
        center: {lat: 12.5225312, lng: 104.920603},
        zoom: 14.5,
        /*fullscreenControl: false,
        mapTypeControl: false,
        rotateControl: false,
        scaleControl: false,
        streetViewControl: false,
        zoomControl: false*/
      },
      isMap: false,
      optionsA: {
        position: {lat: 12.5225312, lng: 104.920603},
        icon: {
          url: "https://maps.google.com/mapfiles/ms/micons/purple-dot.png"
        },
        title: "Property Location"
      },
      circle: {
        center: {lat: 12.5225312, lng: 104.920603},
        radius: 500
      },
      infoOptions: {
        position: {
          lat: 12.5225312,
          lng: 104.920603,
        },
      },
      videoEmbedCode: "",
      renderInfoWindow: false,
      propertyDoc: {},
      index: null,
      page: 1,
      totalPage: 0,
      bClosed: false
    };
  },
  beforeRouteUpdate(to, from, next) {
    let vm = this;
    if (to.params.propertyId !== from.params.propertyId) {
      vm.getPropertyById(to.params.propertyId);
    }
    window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    next();
  },
  methods: {
    handleClickMap() {
      if (this.$refs.b) {
        this.$refs.b.open();
      }
    },
    forceRerender() {
      // Remove my-component from the DOM
      this.renderInfoWindow = false;

      // this.$nextTick(() => {
      //   // Add the component back in
      //   this.renderInfoWindow = true;
      // });
    },
    handleChange(val) {
      this.imgUrlBig = val || "";
    },
    getMore() {
      let vm = this;
      let cDoc = countyMobileList.find((o) => {
        return (
            o.value === vm.$store.state.country ||
            o.number === vm.$store.state.country
        );
      });
      vm.fetchProperty({
        search: "",
        filter: "",
        skip: vm.skip || 0,
        limit: vm.limit || 12,
        sortBy: "",
        sortDesc: vm.sortDesc || "",
        locationId: vm.propertyDoc.locationId,
        type: vm.propertyDoc.type,
        groupType: vm.propertyDoc.groupType,
        status: "Free",
        isWeb: true,
        minPrice: "",
        maxPrice: "",
        countryId: cDoc.number,
      });
    },
    getPropertyById(propertyId) {
      let vm = this;
      vm.imgList = [];
      // const top = window.pageYOffset;
      // this.currentPoint = top;
      // let goPoint = this.currentPoint > 160 ? 135 : 0;
      // this.$vuetify.goTo(goPoint);
      window.scrollTo({top: 0, left: 0, behavior: "smooth"});
      let url =
          provider.baseURL +
          `/property/byId?propertyId=${propertyId}&isView=${true}&userId=${localStorage.id || ""}`;
      return new Promise((resolve, reject) => {
        axios.get(url, {headers: {token: provider.token}}).then(
            (res) => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.propertyDoc = (res.data.data && res.data.data) || {};

                if (res.data.data.urlList && res.data.data.urlList.length > 0) {
                  res.data.data.urlList.forEach((url) => {
                    if (url.indexOf('firebasestorage') > -1) {
                      vm.imgList.push(url);
                    } else if (url.indexOf('imagedelivery') > -1) {
                      vm.imgList.push(url + "/web")
                    } else {
                      vm.imgList.push(url)
                    }
                  });
                }

                if (vm.propertyDoc) {
                  vm.getMore();

                  vm.imgUrlBig = vm.propertyDoc.url1 || "";
                  if (vm.propertyDoc.url1) {
                    vm.imgList.push(vm.propertyDoc.url1);
                  }
                  if (vm.propertyDoc.url2) {
                    vm.imgList.push(vm.propertyDoc.url2);
                  }
                  if (vm.propertyDoc.url3) {
                    vm.imgList.push(vm.propertyDoc.url3);
                  }
                  if (vm.propertyDoc.url4) {
                    vm.imgList.push(vm.propertyDoc.url4);
                  }
                  if (vm.propertyDoc.url5) {
                    vm.imgList.push(vm.propertyDoc.url5);
                  }
                  if (vm.propertyDoc.url6) {
                    vm.imgList.push(vm.propertyDoc.url6);
                  }

                  vm.optionsA.position = (vm.propertyDoc &&
                      vm.propertyDoc.position) || {
                    lat: 12.5225312,
                    lng: 104.920603,
                  };
                  vm.circle.center = (vm.propertyDoc &&
                      vm.propertyDoc.position) || {
                    lat: 12.5225312,
                    lng: 104.920603,
                  };
                  vm.mapOptions.center = (vm.propertyDoc &&
                      vm.propertyDoc.position) || {
                    lat: 12.5225312,
                    lng: 104.920603,
                  };
                  vm.infoOptions = {
                    position: (vm.propertyDoc && vm.propertyDoc.position) || {
                      lat: 12.5225312,
                      lng: 104.920603,
                    },
                  };
                  let rawShareUrl = document.URL;
                  vm.shareUrl =
                      (res.data && res.data.data && res.data.data.shareUrl) ||
                      rawShareUrl;
                  document.title = vm.propertyDoc.title;
                  for (let a of vm.propertyDoc.additional) {
                    vm.amenityListOptResult.push(vm.amenityListOpt.filter(d => d.value == a)[0]);
                  }
                  vm.forceRerender();
                }
              }
            },
            (error) => {
              reject(error);
            }
        );
      });
    },
    queryRandomAgents: _.debounce(function () {
      let vm = this;
      vm.fetchRandomAgents({
        countryId: vm.propertyDoc.countryCode || "",
      });
    }, 500)
  },
  watch: {
    "propertyDoc.linkYoutube"(val) {
      this.videoEmbedCode = "";
      if (val && val.split("youtu.be").length === 2) {
        this.videoEmbedCode = val.replace("youtu.be", "youtube.com/embed");
      } else if (val && val.split("/watch?v=").length > 0) {
        this.videoEmbedCode =
            "https://www.youtube.com/embed/" +
            val.split("/watch?v=")[1].split("&list=")[0].split("&t=")[0];
      }
    },
    page(val) {
      let vm = this;
      vm.loading = true;
      vm.skip = 12 * (val - 1);
      vm.limit = 12 + 12 * (val - 1);
      vm.getMore(vm.skip, vm.limit);
    },
  },
  computed: {},
  created() {
    let vm = this;
    vm.propertyId = vm.$route.params.propertyId;
    console.log(vm.propertyId)

    if (!(vm.propertyId && vm.propertyId !== "")) {
      vm.$router.replace("/");
    }
    vm.link = window.location.href;
    vm.getPropertyById(vm.propertyId);
    vm.queryRandomAgents();
  },
};
</script>

<style scoped>
.height {
  height: 500px;
}

.noPadding {
  padding: 0;
}

.noPaddingTopBottom {
  padding-top: 0;
  padding-bottom: 0;
}

.property-price {
  background-color: purple;
  color: #fff;
  font-size: 20px;
  float: left;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px;
  box-shadow: 3px 3px 3px 0px #888888;
}

.basil--text {
  color: purple;
}

.align-center {
  text-align: center;
}

.agentLink {
  text-decoration: none;
  color: purple;
}
</style>
